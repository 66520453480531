export default {
    "Alert.error": "Error",
    "Alert.info": "Información",
    "Alert.success": "éxito",
    "Alert.warning": "Advertencia",
    "AppointmentCancelled.title": "Esta cita ha sido cancelada",
    "BackButton.back": "atrás",
    "BackButton.back_results": "Regresar a resultados",
    "CallbackRequest.callback_request_disabled": "Actualmente no aceptamos solicitudes de devolución de llamada.",
    "CallbackRequest.not_available": "No disponible",
    "CallbackService.approximately": "Aproximadamente",
    "CallbackService.approximately_x_minutes": "Aproximadamente {minutes} minutos",
    "CallbackService.callback_button": "Llámame",
    "CallbackService.callback_service": "{vendor} de devolución de llamada del proveedor",
    "CallbackService.cancel_appointment": "Cancelar cita",
    "CallbackService.cancel_appointment_confirm": "¿Está seguro de que desea cancelar esta cita? La cancelación de una cita no se puede deshacer.",
    "CallbackService.cancel_callback": "Cancelar devolución de llamada",
    "CallbackService.changes_to_your_appointment": "Cambios a su cita",
    "CallbackService.curbside.radio.car_details": "La marca, el modelo y el color de su automóvil",
    "CallbackService.curbside.radio.curbside": "Acera",
    "CallbackService.curbside.radio.joining_line": "¿Cómo te unes a la línea?",
    "CallbackService.curbside.radio.parking_details": "Número de lugar de estacionamiento",
    "CallbackService.curbside.radio.pickup_information": "Información de recogida en la acera",
    "CallbackService.curbside.radio.walkin": "Entrar",
    "CallbackService.curbsideservices": "Servicios en la acera",
    "CallbackService.email": "Correo electrónico",
    "CallbackService.en": "Inglés",
    "CallbackService.es": "Español",
    "CallbackService.first_name": "Nombre de pila",
    "CallbackService.footer": "Desarrollado por",
    "CallbackService.fr": "Francés",
    "CallbackService.instructions": "Agréguese para ser visto hoy. Cuando un miembro del personal esté listo para atenderlo, le notificaremos que regrese.",
    "CallbackService.join_by_phone": "Únase por teléfono: <a> {number} <\/a>",
    "CallbackService.join_the_call": "Únase a la llamada",
    "CallbackService.ko": "coreano",
    "CallbackService.last_name": "Apellido",
    "CallbackService.location_closed": "La ubicación está cerrada hoy",
    "CallbackService.location_closed_info": "Nuestros agentes responden a estas solicitudes durante estas horas.",
    "CallbackService.location_info": "Comuníquese directamente con la ubicación para obtener servicio.",
    "CallbackService.missing_phone_and_email": "Te avisaremos cuando sea tu turno. Proporcione un número de teléfono o un correo electrónico",
    "CallbackService.no_staff_warning": "Al unirse a la fila, será atendido por el primer miembro del personal disponible. Es posible que este no sea el miembro del personal, {name} que habías seleccionado previamente",
    "CallbackService.not_taking_callbacks": "Esta ubicación no recibe devoluciones de llamadas telefónicas en este momento.",
    "CallbackService.not_taking_online_queue": "Esta ubicación no acepta solicitudes de cola en línea en este momento.",
    "CallbackService.notes": "Notas",
    "CallbackService.notes_error": "",
    "CallbackService.online_queuing": "{vendorName} Cola en línea",
    "CallbackService.optional": "Opcional",
    "CallbackService.phone": "Número de teléfono para llamar",
    "CallbackService.phone_type": "Teléfono",
    "CallbackService.pin": "PIN: {pin}",
    "CallbackService.pl": "Polaco",
    "CallbackService.pt": "portugués",
    "CallbackService.queue_appointment_details": "Detalles de la cita en cola",
    "CallbackService.required": "Necesario",
    "CallbackService.ru": "ruso",
    "CallbackService.sms_opt_in": "Quiero recibir mensajes de texto sobre mi cita.",
    "CallbackService.sms_required": "Recibirá actualizaciones de texto sobre su cita a través de este número. Se pueden aplicar tarifas de mensajería estándar.",
    "CallbackService.submit_form": "Enviar",
    "CallbackService.successfully_added": "Su solicitud ha sido agregada exitosamente",
    "CallbackService.topic": "Seleccione un servicio",
    "CallbackService.topic_prefill": "{name} , seleccione un servicio",
    "CallbackService.topic_wait_time": "{waitTime} Tiempo de espera estimado",
    "CallbackService.video_type": "Video",
    "CallbackService.walkinservices": "Servicios sin cita previa",
    "CallbackService.we_will_notify_you": "Le notificaremos cuando sea el próximo",
    "CallbackService.we_will_notify_you_turn": "Te avisaremos cuando sea tu turno",
    "CallbackService.well_call_you_back_at": "Lo llamaremos a este {phoneNumber}",
    "CallbackService.without_updates": "No, regístrese sin recibir actualizaciones",
    "CallbackService.x_minutes": "{minutes} minutos",
    "CallbackService.you_are_next": "Usted es el próximo",
    "CallbackService.your_appointment": "Su cita",
    "CallbackService.zh": "Chino",
    "CircularProgress.loading": "Cargando",
    "Convert.body": "Puede reservar una cita para una fecha posterior que pueda ser más conveniente. Si elige continuar, solo perderá su lugar en la fila si completa el proceso de reserva de cita.",
    "Convert.button": "Convertir en cita",
    "Convert.title": "¿Cansado de esperar?",
    "CountrySelectInput.label": "País",
    "Days.friday": "Viernes",
    "Days.monday": "Lunes",
    "Days.saturday": "Sábado",
    "Days.sunday": "Domingo",
    "Days.thursday": "Jueves",
    "Days.tuesday": "Martes",
    "Days.wednesday": "Miércoles",
    "Details.confirmation_number": "Número de confirmación",
    "Details.curbside_appointment_title": "Detalles de la cita en la acera",
    "Details.curbside_details_label": "Información de recogida en la acera",
    "Details.date": "Fecha",
    "Details.location": "Ubicación",
    "Details.phone_call": "Llamada telefónica",
    "Details.phone_number_ending_in": "Número de teléfono que termina en {number}",
    "Details.queue_appointment_details": "Detalles de la cita en cola",
    "Details.service": "Servicio",
    "Details.staff": "Personal",
    "Details.video_call": "Videollamada",
    "Details.we_will_call_you": "Te llamaremos al número que nos proporcionaste",
    "Details.we_will_send_link": "Te enviaremos un enlace para unirte a la llamada cuando sea tu turno.",
    "DetailsForm.agree_tos": "Al reservar una cita, usted acepta los {terms} y {privacy} de Coconut Software.",
    "DetailsForm.agree_tos_with_privacy": "Al reservar una cita, usted acepta los {terms} y {privacy} y {vendor} Coconut Software {vendorPrivacy} .",
    "DetailsForm.agree_tos_with_tos": "Al reservar una cita, usted acepta los {terms} y {privacy} y {vendor} Coconut Software {terms} {vendorTerms} .",
    "DetailsForm.agree_tos_with_vendor": "Al reservar una cita, usted acepta los {terms} y {privacy} y {vendor} Coconut Software {terms} {vendorTerms} y {vendorPrivacy} .",
    "DetailsForm.agree_tos_with_vendor_full_privacy": "Al reservar una cita que está de acuerdo con {vendor} 's {vendorTerms} y {vendorPrivacy} .",
    "DetailsForm.agree_tos_with_vendor_full_privacy_policy": "Al reservar una cita que está de acuerdo con {vendor} 's {vendorPrivacy} .",
    "DetailsForm.agree_tos_with_vendor_full_privacy_tos": "Al reservar una cita que está de acuerdo con {vendor} 's {vendorTerms} .",
    "DetailsForm.coconut_privacy_url": "https:\/\/www.coconutsoftware.com\/privacy-policy\/",
    "DetailsForm.coconut_terms_url": "https:\/\/www.coconutsoftware.com\/terms-of-use\/",
    "DetailsForm.explicit_compliance_agreement": "acepto",
    "DetailsForm.privacy": "aviso de privacidad",
    "DetailsForm.terms_of_use": "condiciones de uso",
    "DetailsForm.walkin_policy_action": "",
    "EmptyState.contact_vendor": "Por favor intente nuevamente o contáctenos",
    "Error": "Hubo un error con su solicitud",
    "Feedback.error": "Proporcione una calificación antes de enviar",
    "Feedback.experience": "Tu experiencia",
    "Feedback.google_description": "Realmente valoramos sus comentarios y creemos que nuestros otros clientes se beneficiarán de ellos. Si tiene unos momentos para informarles sobre su experiencia con nosotros a través de una revisión de Google, haga clic en el botón a continuación y lo redireccionaremos.",
    "Feedback.google_submit": "Enviar revisión de Google",
    "Feedback.neutral": "Neutral",
    "Feedback.new_title": "Realimentación",
    "Feedback.rating": "Calificación (requerido)",
    "Feedback.response_prompt": "Cuéntanos cómo tu experiencia podría haber sido mejor",
    "Feedback.send": "Envíe sus comentarios",
    "Feedback.sending": "Envío de comentarios",
    "Feedback.subtitle": "Sus comentarios son anónimos. Usaremos esta información para mejorar la experiencia de reserva.",
    "Feedback.success": "Comentarios enviados con éxito, ¡gracias!",
    "Feedback.thank_you": "¡Gracias por tus comentarios!",
    "Feedback.title": "¿Cómo fue tu experiencia al reservar esta cita?",
    "Feedback.very_dissatisfied": "Muy insatisfecho",
    "Feedback.very_satisfied": "Muy Satisfecho",
    "GenericError.subtitle": "La página que busca no se encuentra",
    "GenericError.title": "Huy! Algo salió mal",
    "Instructions.title": "Instrucciones",
    "Location.select_location": "Selecciona una ubicación",
    "LocationDetails.location_details": "Detalles de ubicación",
    "LocationDetails.select_location": "Seleccione esta ubicación",
    "LocationDirectionsButton.text": "Direcciones",
    "LocationHours.closed": "Cerrado",
    "LocationHours.timespan": "{open} - {closed}",
    "LocationHours.title": "Horas de operación",
    "LocationList.no_locations": "No se encontraron ubicaciones",
    "LocationList.title": "Selecciona una ubicación",
    "LocationPhoneButton.text": "Llamar a {number}",
    "LocationPhoneCallButton.text": "Llamar {number}",
    "Manage.book_another": "Reserve otra cita",
    "Manage.restart": "Reiniciar desde la página de inicio",
    "Questions.missing_both": "Proporcione una dirección de correo electrónico o un número de teléfono móvil antes de responder preguntas",
    "Questions.missing_email": "Proporcione una dirección de correo electrónico antes de responder preguntas",
    "Questions.missing_phone": "Proporcione un número de teléfono móvil antes de responder preguntas",
    "SelectInput.default": "Seleccionar",
    "Svg.altText.back": "atrás",
    "Svg.altText.coconutLogo": "Logotipo de software de coco",
    "Svg.altText.dropdown_arrow": "Flecha desplegable",
    "Svg.altText.location_pin": "Pin de ubicación",
    "Svg.altText.search": "Buscar",
    "Svg.altText.select_language": "Seleccione el idioma",
    "Svg.alt_text.appointment": "Cita",
    "Svg.alt_text.back": "atrás",
    "Svg.alt_text.cancelled": "Cancelado",
    "Svg.alt_text.coconut_logo": "Logotipo de Coconut Software",
    "Svg.alt_text.curbside": "Icono de servicio en la acera",
    "Svg.alt_text.dropdown_arrow": "Icono de flecha hacia abajo",
    "Svg.alt_text.location_error": "Icono de error de ubicación",
    "Svg.alt_text.location_pin": "Localización",
    "Svg.alt_text.search": "Buscar",
    "Svg.alt_text.select_language": "Seleccione el idioma",
    "Svg.alt_text.up_arrow": "Icono de flecha hacia arriba",
    "Svg.alt_text.walk_in": "Caminar en el icono de servicio",
    "Ui.cancel": "Cancelar cita",
    "Ui.continue": "Continuar",
    "Ui.keep": "Mantenerse",
    "Ui.loading": "Cargando",
    "Ui.lobby_disabled": "",
    "Ui.no_available_staff_inperson": "Esta ubicación no acepta visitas sin cita previa en persona en este momento",
    "Ui.no_available_staff_phone": "Esta ubicación no acepta devoluciones de llamadas telefónicas en este momento",
    "Ui.no_available_staff_video": "Esta ubicación no acepta devoluciones de videollamadas en este momento",
    "Ui.private_location": "Esta ubicación es privada y no puedes unirte a la línea aquí.",
    "Ui.search": "Buscar ...",
    "Ui.search.instructions": "Por favor, comience a escribir para buscar.",
    "Ui.search.plain": "Buscar"
}