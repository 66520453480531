export default {
    "Alert.error": "Błąd",
    "Alert.info": "Informacje",
    "Alert.success": "sukces",
    "Alert.warning": "Ostrzeżenie",
    "AppointmentCancelled.title": "To spotkanie zostało anulowane",
    "BackButton.back": "Wróć",
    "BackButton.back_results": "Wróć do wyników",
    "CallbackRequest.callback_request_disabled": "Obecnie nie przyjmujemy żądań oddzwonienia.",
    "CallbackRequest.not_available": "Niedostępny",
    "CallbackService.approximately": "Około",
    "CallbackService.approximately_x_minutes": "Około :minutes min",
    "CallbackService.callback_button": "Oddzwoń",
    "CallbackService.callback_service": ":vendor Usługa oddzwaniania",
    "CallbackService.cancel_appointment": "Anuluj spotkanie",
    "CallbackService.cancel_appointment_confirm": "Czy na pewno chcesz anulować to spotkanie? Nie można cofnąć anulowania spotkania.",
    "CallbackService.cancel_callback": "Anuluj oddzwonienie",
    "CallbackService.changes_to_your_appointment": "Zmiany dotyczące spotkania",
    "CallbackService.curbside.radio.car_details": "Marka, model i kolor Twojego samochodu",
    "CallbackService.curbside.radio.curbside": "Z odbiorem zamówienia",
    "CallbackService.curbside.radio.joining_line": "W jaki sposób dołączasz do kolejki?",
    "CallbackService.curbside.radio.parking_details": "Numer miejsca parkingowego",
    "CallbackService.curbside.radio.pickup_information": "Informacje o odbiorze własnym zamówienia",
    "CallbackService.curbside.radio.walkin": "Wizyta",
    "CallbackService.curbsideservices": "Usługi odbioru zamówienia",
    "CallbackService.email": "E-mail",
    "CallbackService.en": "Angielski",
    "CallbackService.es": "Hiszpański",
    "CallbackService.first_name": "Imię",
    "CallbackService.footer": "Obsługiwane przez",
    "CallbackService.fr": "Francuski",
    "CallbackService.instructions": "Dodaj siebie jako osobę, z którą ma dziś zostać odbyte spotkanie. Gdy członek personelu będzie gotowy do obsłużenia Cię, powiadomimy Cię, aby wrócić.",
    "CallbackService.join_by_phone": "Dołącz przez telefon: <a>{number}<\/a>",
    "CallbackService.join_the_call": "Dołącz do rozmowy",
    "CallbackService.ko": "koreański",
    "CallbackService.last_name": "Nazwisko",
    "CallbackService.location_closed": "Obecnie zamknięte",
    "CallbackService.location_closed_info": "Nasi agenci odpowiadają na te żądania w następujących godzinach",
    "CallbackService.location_info": "W kwestii usługi skontaktuj się bezpośrednio z lokalizacją.",
    "CallbackService.missing_phone_and_email": "Powiadomimy Cię, gdy przyjdzie Twoja kolej. Podaj numer telefonu lub adres e-mail",
    "CallbackService.no_staff_warning": "Dołączając do linii, obsłuży Cię pierwszy dostępny pracownik. To może nie być pracownik {name} , którego wcześniej wybrałeś",
    "CallbackService.not_taking_callbacks": "W tej chwili ta lokalizacja nie przyjmuje żądań oddzwonienia.",
    "CallbackService.not_taking_online_queue": "W tej chwili ta lokalizacja nie przyjmuje żądań kolejki online.",
    "CallbackService.notes": "Uwagi",
    "CallbackService.notes_error": "",
    "CallbackService.online_queuing": "{vendorName} — kolejka online",
    "CallbackService.optional": "Opcjonalny",
    "CallbackService.phone": "Numer, pod który można do Ciebie zadzwonić",
    "CallbackService.phone_type": "Telefon",
    "CallbackService.pin": "Kod PIN: {pin}",
    "CallbackService.pl": "Polski",
    "CallbackService.pt": "portugalski",
    "CallbackService.queue_appointment_details": "Szczegóły spotkania w kolejce",
    "CallbackService.required": "Wymagany",
    "CallbackService.ru": "Rosyjski",
    "CallbackService.sms_opt_in": "Chcę otrzymywać wiadomości SMS o moim spotkaniu",
    "CallbackService.sms_required": "Pod tym numerem będziesz otrzymywać wiadomości SMS z informacjami o spotkaniu. Mogą obowiązywać standardowe stawki za wysyłanie wiadomości.",
    "CallbackService.submit_form": "Prześlij",
    "CallbackService.successfully_added": "Twoje żądanie zostało dodane",
    "CallbackService.topic": "Wybierz usługę",
    "CallbackService.topic_prefill": "{name}, wybierz usługę",
    "CallbackService.topic_wait_time": "{waitTime} — szacowany czas oczekiwania",
    "CallbackService.video_type": "Wideo",
    "CallbackService.walkinservices": "Usługi wizyt",
    "CallbackService.we_will_notify_you": "Powiadomimy Cię, gdy będziesz następną osobą w kolejce",
    "CallbackService.we_will_notify_you_turn": "Powiadomimy Cię, gdy przyjdzie Twoja kolej",
    "CallbackService.well_call_you_back_at": "Oddzwonimy do Ciebie pod numer :phoneNumber",
    "CallbackService.without_updates": "Nie, zamelduj się bez otrzymywania aktualizacji",
    "CallbackService.x_minutes": "{minutes} min",
    "CallbackService.you_are_next": "Jesteś następną osobą do oddzwonienia.",
    "CallbackService.your_appointment": "Twoje spotkanie",
    "CallbackService.zh": "chiński",
    "CircularProgress.loading": "Ładowanie",
    "Convert.body": "Możesz zarezerwować późniejszy termin spotkania, być może dogodniejszy. Jeśli zdecydujesz się kontynuować, stracisz miejsce w kolejce tylko wtedy, gdy ukończysz proces rezerwacji terminu.",
    "Convert.button": "Przekonwertuj na spotkanie",
    "Convert.title": "Masz dość czekania?",
    "CountrySelectInput.label": "Kraj",
    "Days.friday": "Piątek",
    "Days.monday": "Poniedziałek",
    "Days.saturday": "Sobota",
    "Days.sunday": "Niedziela",
    "Days.thursday": "Czwartek",
    "Days.tuesday": "Wtorek",
    "Days.wednesday": "Środa",
    "Details.confirmation_number": "Numer potwierdzenia",
    "Details.curbside_appointment_title": "Szczegóły spotkania w sprawie odbioru zamówienia",
    "Details.curbside_details_label": "Informacje o odbiorze własnym zamówienia",
    "Details.date": "Data",
    "Details.location": "Lokalizacja",
    "Details.phone_call": "Rozmowa telefoniczna",
    "Details.phone_number_ending_in": "Numer telefonu kończący się na {number}",
    "Details.queue_appointment_details": "Szczegóły spotkania w kolejce",
    "Details.service": "Usługa",
    "Details.staff": "Personel",
    "Details.video_call": "Wideorozmowa",
    "Details.we_will_call_you": "Zadzwonimy do Ciebie pod podany przez Ciebie numer",
    "Details.we_will_send_link": "Gdy przyjdzie Twoja kolej, wyślemy Ci link, za pomocą którego można dołączyć do rozmowy",
    "DetailsForm.agree_tos": "Rezerwując spotkanie, akceptujesz {terms} i {privacy} firmy Coconut Software.",
    "DetailsForm.agree_tos_with_privacy": "Rezerwując spotkanie, akceptujesz {terms} i {privacy} firmy Coconut Software oraz {vendorPrivacy} dostawcy {vendor}.",
    "DetailsForm.agree_tos_with_tos": "Rezerwując spotkanie, akceptujesz {terms} i {privacy} firmy Coconut Software oraz {vendorTerms} dostawcy {vendor}.",
    "DetailsForm.agree_tos_with_vendor": "Rezerwując spotkanie, akceptujesz {terms} i {privacy} firmy Coconut Software oraz {vendorTerms} i {vendorPrivacy} dostawcy {vendor}.",
    "DetailsForm.agree_tos_with_vendor_full_privacy": "Rezerwując spotkanie, akceptujesz {vendorTerms} i {vendorPrivacy} dostawcy {vendor}.",
    "DetailsForm.agree_tos_with_vendor_full_privacy_policy": "Rezerwując spotkanie, akceptujesz {vendorPrivacy} dostawcy {vendor}.",
    "DetailsForm.agree_tos_with_vendor_full_privacy_tos": "Rezerwując spotkanie, akceptujesz {vendorTerms} dostawcy {vendor}.",
    "DetailsForm.coconut_privacy_url": "https:\/\/www.coconutsoftware.com\/privacy-policy\/",
    "DetailsForm.coconut_terms_url": "https:\/\/www.coconutsoftware.com\/terms-of-use\/",
    "DetailsForm.explicit_compliance_agreement": "Akceptuję",
    "DetailsForm.privacy": "informacja o ochronie prywatności",
    "DetailsForm.terms_of_use": "warunki korzystania",
    "DetailsForm.walkin_policy_action": "",
    "EmptyState.contact_vendor": "Spróbuj ponownie lub skontaktuj się z nami",
    "Error": "Wystąpił błąd",
    "Feedback.error": "Przed przesłaniem wystaw ocenę",
    "Feedback.experience": "Twoje doświadczenie",
    "Feedback.google_description": "Bardzo cenimy sobie Twoją opinię i wierzymy, że skorzystają z niej inni nasi klienci. Jeśli masz chwilę, aby poinformować o swoich doświadczeniach z nami za pośrednictwem recenzji Google, kliknij poniższy przycisk, a my Cię przekierujemy.",
    "Feedback.google_submit": "Prześlij recenzję Google",
    "Feedback.neutral": "Neutralna",
    "Feedback.new_title": "Opinia",
    "Feedback.rating": "Ocena (wymagana)",
    "Feedback.response_prompt": "Powiedz nam, pod jakim względem Twoje doświadczenie mogłoby być lepsze",
    "Feedback.send": "Wyślij opinię",
    "Feedback.sending": "Wysyłanie opinii",
    "Feedback.subtitle": "Twoja opinia jest anonimowa. Wykorzystamy te informacje, aby poprawić doświadczenia z rezerwacji.",
    "Feedback.success": "Opinia została wysłana, dziękujemy!",
    "Feedback.thank_you": "Dziękujemy za opinię!",
    "Feedback.title": "Jakie było Twoje doświadczenie związane z rezerwacją spotkania?",
    "Feedback.very_dissatisfied": "Bardzo niezadowalające",
    "Feedback.very_satisfied": "Bardzo zadowalające",
    "GenericError.subtitle": "Nie można znaleźć strony, której szukasz",
    "GenericError.title": "Ups, coś poszło nie tak",
    "Instructions.title": "Instrukcje",
    "Location.select_location": "Wybierz lokalizację",
    "LocationDetails.location_details": "Szczegóły lokalizacji",
    "LocationDetails.select_location": "Wybierz tę lokalizację",
    "LocationDirectionsButton.text": "Wskazówki dojazdu",
    "LocationHours.closed": "Zamknięte",
    "LocationHours.timespan": "{open}–{closed}",
    "LocationHours.title": "Godziny pracy",
    "LocationList.no_locations": "Nie znaleziono żadnych lokalizacji",
    "LocationList.title": "Wybierz lokalizację",
    "LocationPhoneButton.text": "Zadzwoń pod numer {number}",
    "LocationPhoneCallButton.text": "Zadzwoń pod numer {number}",
    "Manage.book_another": "Zarezerwuj inny termin spotkania",
    "Manage.restart": "Zacznij ponownie ze strony głównej",
    "Questions.missing_both": "Przed udzieleniem odpowiedzi na pytania podaj adres e-mail lub numer telefonu komórkowego",
    "Questions.missing_email": "Przed udzieleniem odpowiedzi na pytania podaj adres e-mail",
    "Questions.missing_phone": "Podaj numer telefonu komórkowego przed udzieleniem odpowiedzi na pytania",
    "SelectInput.default": "Wybierz",
    "Svg.altText.back": "Wróć",
    "Svg.altText.coconutLogo": "Logotyp firmy Coconut Software",
    "Svg.altText.dropdown_arrow": "Strzałka listy rozwijanej",
    "Svg.altText.location_pin": "Pinezka lokalizacji",
    "Svg.altText.search": "Wyszukaj",
    "Svg.altText.select_language": "Wybierz język",
    "Svg.alt_text.appointment": "Spotkanie",
    "Svg.alt_text.back": "Wróć",
    "Svg.alt_text.cancelled": "Anulowano",
    "Svg.alt_text.coconut_logo": "Logotyp firmy Coconut Software",
    "Svg.alt_text.curbside": "Ikona usługi odbioru zamówienia",
    "Svg.alt_text.dropdown_arrow": "Ikona strzałki w dół",
    "Svg.alt_text.location_error": "Ikona błędu lokalizacji",
    "Svg.alt_text.location_pin": "Lokalizacja",
    "Svg.alt_text.search": "Wyszukaj",
    "Svg.alt_text.select_language": "Wybierz język",
    "Svg.alt_text.up_arrow": "Ikona strzałki w górę",
    "Svg.alt_text.walk_in": "Ikona usługi wizyty",
    "Ui.cancel": "Anuluj spotkanie",
    "Ui.continue": "Kontynuuj",
    "Ui.keep": "Zachowaj",
    "Ui.loading": "Ładowanie",
    "Ui.lobby_disabled": "",
    "Ui.no_available_staff_inperson": "Obecnie ta lokalizacja nie przyjmuje osób",
    "Ui.no_available_staff_phone": "Ta lokalizacja nie przyjmuje obecnie połączeń zwrotnych",
    "Ui.no_available_staff_video": "Ta lokalizacja nie przyjmuje obecnie połączeń zwrotnych wideo",
    "Ui.private_location": "Ta lokalizacja jest prywatna i nie możesz tu dołączyć do linii",
    "Ui.search": "Wyszukaj...",
    "Ui.search.instructions": "Aby wyszukać, zacznij pisać.",
    "Ui.search.plain": "Szukaj"
}