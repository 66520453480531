export default {
    "Alert.error": "Erro",
    "Alert.info": "Informação",
    "Alert.success": "êxito",
    "Alert.warning": "Aviso",
    "AppointmentCancelled.title": "Este compromisso foi cancelado",
    "BackButton.back": "Voltar",
    "BackButton.back_results": "Voltar para resultados",
    "CallbackRequest.callback_request_disabled": "Não estamos aceitando solicitações de retorno de chamada no momento.",
    "CallbackRequest.not_available": "Não disponível",
    "CallbackService.approximately": "Aproximadamente",
    "CallbackService.approximately_x_minutes": "Aproximadamente :minutes minutos",
    "CallbackService.callback_button": "Ligar de volta para mim",
    "CallbackService.callback_service": "Serviço de retorno de chamada da :vendor ",
    "CallbackService.cancel_appointment": "Cancelar compromisso",
    "CallbackService.cancel_appointment_confirm": "Tem certeza de que deseja cancelar este compromisso? O cancelamento do compromisso não pode ser desfeito.",
    "CallbackService.cancel_callback": "Cancelar retorno de chamada",
    "CallbackService.changes_to_your_appointment": "Alterações em seu compromisso",
    "CallbackService.curbside.radio.car_details": "Marca, modelo e cor do seu carro",
    "CallbackService.curbside.radio.curbside": "Curbside",
    "CallbackService.curbside.radio.joining_line": "Como você entrará na fila?",
    "CallbackService.curbside.radio.parking_details": "Número da vaga de estacionamento",
    "CallbackService.curbside.radio.pickup_information": "Informações do Curbside Pickup",
    "CallbackService.curbside.radio.walkin": "Walk-in",
    "CallbackService.curbsideservices": "Serviços curbside",
    "CallbackService.email": "E-mail",
    "CallbackService.en": "Inglês",
    "CallbackService.es": "Espanhol",
    "CallbackService.first_name": "Nome",
    "CallbackService.footer": "Com a tecnologia",
    "CallbackService.fr": "Francês",
    "CallbackService.instructions": "Adicione a si mesmo para ser atendido hoje. Quando um membro da equipe estiver pronto para o atendimento, enviaremos uma notificação para que você volte.",
    "CallbackService.join_by_phone": "Participar por telefone: <a>{number}<\/a>",
    "CallbackService.join_the_call": "Entrar na chamada",
    "CallbackService.ko": "Coreano",
    "CallbackService.last_name": "Sobrenome",
    "CallbackService.location_closed": "Atualmente fechada",
    "CallbackService.location_closed_info": "Nossos agentes respondem a essas solicitações durante esse horário",
    "CallbackService.location_info": "Entre em contato com o local diretamente para o serviço.",
    "CallbackService.missing_phone_and_email": "Avisaremos quando chegar a sua vez. Forneça um número de telefone ou e-mail",
    "CallbackService.no_staff_warning": "Ao entrar na fila, você será atendido pelo primeiro funcionário disponível. Este pode não ser o membro da equipe, {name} que você selecionou anteriormente",
    "CallbackService.not_taking_callbacks": "Este local não está aceitando retornos de chamadas telefônicas no momento.",
    "CallbackService.not_taking_online_queue": "Este local não está aceitando solicitações de fila online no momento.",
    "CallbackService.notes": "Notas",
    "CallbackService.notes_error": "",
    "CallbackService.online_queuing": "Fila online de {vendorName}",
    "CallbackService.optional": "Opcional",
    "CallbackService.phone": "Número para ligar para você",
    "CallbackService.phone_type": "Telefone",
    "CallbackService.pin": "PIN: {pin}",
    "CallbackService.pl": "Polonês",
    "CallbackService.pt": "Português",
    "CallbackService.queue_appointment_details": "Detalhes do compromisso na fila",
    "CallbackService.required": "Obrigatório",
    "CallbackService.ru": "Russo",
    "CallbackService.sms_opt_in": "Quero receber mensagens de texto sobre meu compromisso",
    "CallbackService.sms_required": "Você receberá atualizações por SMS sobre seu compromisso nesse número. Taxas padrão de envio de mensagens podem ser cobradas.",
    "CallbackService.submit_form": "Enviar",
    "CallbackService.successfully_added": "Sua solicitação foi adicionada",
    "CallbackService.topic": "Selecionar um serviço",
    "CallbackService.topic_prefill": "{name}, selecione um serviço",
    "CallbackService.topic_wait_time": "{waitTime} é o tempo de espera estimado",
    "CallbackService.video_type": "Vídeo",
    "CallbackService.walkinservices": "Serviços walk-in",
    "CallbackService.we_will_notify_you": "Avisaremos quando você for o próximo",
    "CallbackService.we_will_notify_you_turn": "Avisaremos quando chegar a sua vez",
    "CallbackService.well_call_you_back_at": "Ligaremos de volta para você em :phoneNumber",
    "CallbackService.without_updates": "Não, fazer check-in sem obter atualizações",
    "CallbackService.x_minutes": "{minutes} minutos",
    "CallbackService.you_are_next": "Você é o próximo a receber um retorno de chamada.",
    "CallbackService.your_appointment": "Seu compromisso",
    "CallbackService.zh": "chinês",
    "CircularProgress.loading": "Carregando",
    "Convert.body": "Você pode agendar um compromisso em outra data que seja mais conveniente. Se você optar por continuar, perderá seu lugar na fila somente se concluir o processo de agendamento de compromisso.",
    "Convert.button": "Converter em compromisso",
    "Convert.title": "Cansado de esperar?",
    "CountrySelectInput.label": "País",
    "Days.friday": "Sexta-feira",
    "Days.monday": "Segunda-feira",
    "Days.saturday": "Sábado",
    "Days.sunday": "Domingo",
    "Days.thursday": "Quinta-feira",
    "Days.tuesday": "Terça-feira",
    "Days.wednesday": "Quarta-feira",
    "Details.confirmation_number": "Número de confirmação",
    "Details.curbside_appointment_title": "Detalhes do compromisso de curbside",
    "Details.curbside_details_label": "Informações do Curbside Pickup",
    "Details.date": "Data",
    "Details.location": "Local",
    "Details.phone_call": "Telefonema",
    "Details.phone_number_ending_in": "Número de telefone com final {number}",
    "Details.queue_appointment_details": "Detalhes do compromisso na fila",
    "Details.service": "Serviço",
    "Details.staff": "Equipe",
    "Details.video_call": "Chamada de vídeo",
    "Details.we_will_call_you": "Ligaremos para você no número fornecido",
    "Details.we_will_send_link": "Enviaremos a você informações sobre como entrar na chamada quando chegar a sua vez",
    "DetailsForm.agree_tos": "Ao agendar um compromisso, você concorda com os {terms} e o {privacy} da Coconut Software.",
    "DetailsForm.agree_tos_with_privacy": "Ao agendar um compromisso, você concorda com os {terms} e o {privacy} da Coconut Software e com o {vendorPrivacy} da {vendor}.",
    "DetailsForm.agree_tos_with_tos": "Ao agendar um compromisso, você concorda com os {terms} e o {privacy} da Coconut Software e com os {vendorTerms} da {vendor}.",
    "DetailsForm.agree_tos_with_vendor": "Ao agendar um compromisso, você concorda com os {terms} e o {privacy} da Coconut Software e com os {vendorTerms} e o {vendorPrivacy} da {vendor}.",
    "DetailsForm.agree_tos_with_vendor_full_privacy": "Ao agendar um compromisso, você concorda com os {vendorTerms} e o {vendorPrivacy} da {vendor}.",
    "DetailsForm.agree_tos_with_vendor_full_privacy_policy": "Ao agendar um compromisso, você concorda com o {vendorPrivacy} da {vendor}.",
    "DetailsForm.agree_tos_with_vendor_full_privacy_tos": "Ao agendar um compromisso, você concorda com os {vendorTerms} da {vendor}.",
    "DetailsForm.coconut_privacy_url": "https:\/\/www.coconutsoftware.com\/privacy-policy\/",
    "DetailsForm.coconut_terms_url": "https:\/\/www.coconutsoftware.com\/terms-of-use\/",
    "DetailsForm.explicit_compliance_agreement": "Aceito",
    "DetailsForm.privacy": "aviso de privacidade",
    "DetailsForm.terms_of_use": "termos de uso",
    "DetailsForm.walkin_policy_action": "",
    "EmptyState.contact_vendor": "Tente novamente ou entre em contato conosco",
    "Error": "Ocorreu um erro em sua solicitação",
    "Feedback.error": "Forneça uma classificação antes de enviar",
    "Feedback.experience": "Sua experiência",
    "Feedback.google_description": "Valorizamos muito seu feedback e acreditamos que nossos outros clientes se beneficiarão disso. Se tiver um tempinho para nos contar sua experiência conosco usando uma avaliação do Google, clique no botão abaixo e redirecionaremos você.",
    "Feedback.google_submit": "Enviar avaliação do Google",
    "Feedback.neutral": "Neutro",
    "Feedback.new_title": "Feedback",
    "Feedback.rating": "Classificação (obrigatório)",
    "Feedback.response_prompt": "Conte para nós como sua experiência poderia ter sido melhor",
    "Feedback.send": "Enviar feedback",
    "Feedback.sending": "Enviando feedback",
    "Feedback.subtitle": "Seu feedback é anônimo. Usaremos essas informações para melhorar sua experiência de agendamento.",
    "Feedback.success": "Feedback enviado. Nossos agradecimentos!",
    "Feedback.thank_you": "Agradecemos o seu feedback!",
    "Feedback.title": "Como foi sua experiência ao agendar este compromisso?",
    "Feedback.very_dissatisfied": "Muito insatisfeito",
    "Feedback.very_satisfied": "Muito satisfeito",
    "GenericError.subtitle": "A página que você está procurando não foi encontrada",
    "GenericError.title": "Opa, algo deu errado",
    "Instructions.title": "Instruções",
    "Location.select_location": "Selecionar um local",
    "LocationDetails.location_details": "Detalhes do local",
    "LocationDetails.select_location": "Selecionar este local",
    "LocationDirectionsButton.text": "Como chegar",
    "LocationHours.closed": "Fechado",
    "LocationHours.timespan": "{open} - {closed}",
    "LocationHours.title": "Horário de funcionamento",
    "LocationList.no_locations": "Nenhum local encontrado",
    "LocationList.title": "Selecionar um local",
    "LocationPhoneButton.text": "Ligar para {number}",
    "LocationPhoneCallButton.text": "Ligar para {number}",
    "Manage.book_another": "Agendar outro compromisso",
    "Manage.restart": "Reiniciar pela página inicial",
    "Questions.missing_both": "Forneça um endereço de e-mail ou número de celular antes de responder às perguntas",
    "Questions.missing_email": "Forneça um endereço de e-mail antes de responder às perguntas",
    "Questions.missing_phone": "Forneça um número de celular antes de responder às perguntas",
    "SelectInput.default": "Selecionar",
    "Svg.altText.back": "Voltar",
    "Svg.altText.coconutLogo": "Logotipo da Coconut Software",
    "Svg.altText.dropdown_arrow": "Seta suspensa",
    "Svg.altText.location_pin": "Pino do local",
    "Svg.altText.search": "Pesquisar",
    "Svg.altText.select_language": "Selecionar idioma",
    "Svg.alt_text.appointment": "Compromisso",
    "Svg.alt_text.back": "Voltar",
    "Svg.alt_text.cancelled": "Cancelado",
    "Svg.alt_text.coconut_logo": "Logotipo da Coconut Software",
    "Svg.alt_text.curbside": "Ícone do serviço curbside",
    "Svg.alt_text.dropdown_arrow": "Ícone de seta para baixo",
    "Svg.alt_text.location_error": "Ícone de erro de localização",
    "Svg.alt_text.location_pin": "Local",
    "Svg.alt_text.search": "Pesquisar",
    "Svg.alt_text.select_language": "Selecionar idioma",
    "Svg.alt_text.up_arrow": "Ícone de seta para cima",
    "Svg.alt_text.walk_in": "Ícone de serviço walk-in",
    "Ui.cancel": "Cancelar compromisso",
    "Ui.continue": "Continuar",
    "Ui.keep": "Manter",
    "Ui.loading": "Carregando",
    "Ui.lobby_disabled": "",
    "Ui.no_available_staff_inperson": "Este local não está recebendo visitas pessoais no momento",
    "Ui.no_available_staff_phone": "Este local não está recebendo retornos de chamada no momento",
    "Ui.no_available_staff_video": "Este local não está recebendo retornos de chamada de vídeo no momento",
    "Ui.private_location": "Este local é privado e você não pode entrar na fila aqui",
    "Ui.search": "Pesquisar...",
    "Ui.search.instructions": "Por favor, comece a digitar para pesquisar.",
    "Ui.search.plain": "Procurar"
}